<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="text-lg-center">
        <span class="font-weight-semibold">Relación de Número de Personas vs Número de Vehículos</span>
      </p>
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="!loading && !error"
        class="small"
      >
        <line-chart :chart-data="datacollection"></line-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import LineChart from './LineChart'

export default {
  components: {
    LineChart,
  },
  props: {
  },
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading(load) {
      this.loading = load
      this.error = false
    },
    setError(error) {
      this.datacollection = null
      this.error = true
      this.errMessage = error
      this.loading = false
    },
    fillData(vehiculos, personas) {
      const chartDataPersonas = []
      const chartDataVehiculos = []
      const chartLabels = []
      if (vehiculos != null) {
        vehiculos.map(value => {
          chartDataVehiculos.push(value.total)
          chartLabels.push(value.fecha)

          return true
        }, {})
      }
      if (personas != null) {
        personas.map(value => {
          chartDataPersonas.push(value.total)

          return true
        }, {})
      }
      this.datacollection = {
        labels: chartLabels,
        datasets: [
          {
            label: 'Vehiculos',
            backgroundColor: '#028675',
            data: chartDataVehiculos,
          },
          {
            label: 'Personas',
            backgroundColor: '#D68903',
            data: chartDataPersonas,
          },
        ],
      }
      console.log(this.datacollection)
      this.error = false
      this.loading = false
    },
  },
}
</script>
