<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="text-lg-center">
        <span class="font-weight-semibold">Número de Vehículos por Tipo: {{ total | numberFormat }}</span>
      </p>
    </v-card-title>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="detalle && !loading"
        class="small"
      >
        <pie-chart
          v-if="datacollection && !error"
          :chart-data="datacollection"
        ></pie-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import PieChart from './PieChart'

export default {
  components: {
    PieChart,
  },
  props: {
  },
  data() {
    return {
      loading: true,
      datacollection: null,
      detalle: [],
      total: 0,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading(load) {
      this.loading = load
      this.total = 0
      this.datacollection = null
      this.error = false
    },
    setError(error) {
      this.datacollection = null
      this.error = true
      this.errMessage = error
      this.loading = false
    },
    fillData(detalle, total) {
      this.detalle = detalle
      this.total = total
      if (detalle != null) {
        const data = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: ['#028675', '#D68903', '#0F3E8E'],
            },
          ],
        }
        this.detalle.sort((a, b) => {
          if (a.total > b.total) {
            return -1
          }
          if (a.total < b.total) {
            return 1
          }

          return 0
        })
        this.detalle.forEach(e => {
          let tipo = ''
          if (e.tipoauto === 'CAR') {
            tipo = 'Carro'
          } else if (e.tipoauto === 'BIKE') {
            tipo = 'Bicicleta'
          } else {
            tipo = 'Camión'
          }
          data.labels.push(tipo)
          data.datasets[0].data.push(e.total)
        })

        this.datacollection = data
      }
      this.loading = false
    },
  },
}
</script>
