<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <v-combobox
        v-model="selectedCalle"
        :items="itemsCalle"
        label="Calle"
        outlined
        dense
      ></v-combobox>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-combobox
        v-model="selectTipoVehiculo"
        :items="itemsTipoVehiculo"
        label="Tipo de Vehículo"
        outlined
        dense
      ></v-combobox>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-menu
        ref="fechaLocal"
        v-model="fechaLocal"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            persistent-hint
            :prepend-inner-icon="icons.mdiCalendar"
            v-bind="attrs"
            outlined
            readonly
            dense
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          range
          locale="es-MX"
          :max="maxDate"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="fechaLocal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="searchData(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <vehiculos-tipo ref="vehiculosTipo"></vehiculos-tipo>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <vehiculos-dia ref="vehiculosDia"></vehiculos-dia>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <personas-calle ref="personasCalle"></personas-calle>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <personas-dia ref="PersonasDia"></personas-dia>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <personas-vs-vehiculos ref="personasVehiculos"></personas-vs-vehiculos>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <afluencia-vehicular ref="afluenciaVehicular"></afluencia-vehicular>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <afluencia-peatonal ref="afluenciaPeatonal"></afluencia-peatonal>
    </v-col>
  </v-row>
</template>

<script>

import { mdiCalendar } from '@mdi/js'
import moment from 'moment'
import 'moment-timezone'
import auth from '@/services/auth'
import VehiculosTipo from './VehiculosTipo'
import VehiculosDia from './VehiculosDia'
import PersonasCalle from './PersonasCalle'
import PersonasDia from './PersonasDia'
import PersonasVsVehiculos from './PersonasVsVehiculos'
import AfluenciaVehicular from './AfluenciaVehicular'
import AfluenciaPeatonal from './AfluenciaPeatonal'
import analiticaCarga from '@/services/analiticaCarga'

export default {
  components: {
    VehiculosTipo,
    VehiculosDia,
    PersonasCalle,
    PersonasDia,
    PersonasVsVehiculos,
    AfluenciaVehicular,
    AfluenciaPeatonal,
  },
  data: () => ({
    data: null,
    date: [moment().tz('America/Mexico_City').format('YYYY-MM-DD'), moment().tz('America/Mexico_City').subtract(15, 'days').format('YYYY-MM-DD')],
    dateFormatted: `${moment().tz('America/Mexico_City').subtract(15, 'days').format('D MMMM YYYY')} a ${moment().tz('America/Mexico_City').format('D MMMM YYYY')}`,
    fechaLocal: '',
    params: {
      fechaInicio: moment().tz('America/Mexico_City').subtract(15, 'days').format('YYYY-MM-DD'),
      fechaFin: moment().tz('America/Mexico_City').format('YYYY-MM-DD'),
      calle: null,
      tipoauto: null,
    },
    maxDate: moment().tz('America/Mexico_City').format('YYYY-MM-DD'),
    selectTipoVehiculo: 'Todos',
    itemsTipoVehiculo: [
      {
        text: 'Todos',
        value: null,
      },
      {
        text: 'Camión',
        value: 'TRUCK',
      },
      {
        text: 'Carro',
        value: 'CAR',
      },
      {
        text: 'Bicicleta',
        value: 'BIKE',
      },
    ],
    selectedCalle: 'Todos',
    itemsCalle: [
      {
        text: 'Todos',
        value: null,
      },
      {
        text: 'Juárez(Vehículos)',
        value: 'CALLE JUÁREZ',
      },
      {
        text: 'Francisco I. Madero (Vehículos)',
        value: 'CALLE MADERO',
      },
      {
        text: 'Sixto Gorjón (Vehículos)',
        value: 'CALLE SIXTO GORJÓN',
      },
      {
        text: 'Ramón Corona (Ambos)',
        value: 'CALLE RAMÓN CORONA',
      },
      {
        text: 'José Cuervo (Personas)',
        value: 'CALLE JOSÉ CUERVO',
      },
    ],
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    isLoggedIn() {
      return auth.isLoggin()
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
    selectedCalle(val) {
      this.params.calle = val.value
      this.getData()
    },
    selectTipoVehiculo(val) {
      this.params.tipoauto = val.value
      this.getData()
    },
  },
  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push(`/pages/login?return=${this.$route.fullPath}`)
    }
    this.getData()
  },
  methods: {
    searchData(date) {
      this.$refs.fechaLocal.save(date)
      this.getData()
    },
    formatDate(date) {
      if (!date) return null
      const ndate = [...date].sort()
      let f = moment(ndate[0]).format('D MMMM YYYY')
      // eslint-disable-next-line prefer-destructuring
      this.params.fechaInicio = ndate[0]
      // eslint-disable-next-line prefer-destructuring
      this.params.fechaFin = ndate[0]
      if (date.length > 1) {
        f += ` a ${moment(ndate[1]).format('D MMMM YYYY')}`
        // eslint-disable-next-line prefer-destructuring
        this.params.fechaFin = ndate[1]
      }

      return f
    },
    getData() {
      this.$refs.vehiculosTipo.setLoading(true)
      this.$refs.vehiculosDia.setLoading(true)
      this.$refs.personasCalle.setLoading(true)
      this.$refs.PersonasDia.setLoading(true)
      this.$refs.personasVehiculos.setLoading(true)
      this.$refs.afluenciaVehicular.setLoading(true)
      this.$refs.afluenciaPeatonal.setLoading(true)
      analiticaCarga.getTotalesAgrupacion(this.params).then(resp => {
        this.data = resp.data
        if (this.data.error) {
          this.setError(this.data.error)
        } else {
          this.$refs.vehiculosTipo.fillData(this.data.movilidad.detallevehiculos, this.data.movilidad.totalvehiculos)
          this.$refs.vehiculosDia.fillData(this.data.detalle_horas_pico_vehiculos)
          this.$refs.personasCalle.fillData(this.data.movilidad.detallepersonas, this.data.movilidad.totalpersonas)
          this.$refs.PersonasDia.fillData(this.data.detalle_horas_pico_personas)
          this.$refs.personasVehiculos.fillData(this.data.detalle_horas_pico_vehiculos, this.data.detalle_horas_pico_personas)
          this.$refs.afluenciaVehicular.fillData(this.data.movilidad.detallehrsvehiculos)
          this.$refs.afluenciaPeatonal.fillData(this.data.movilidad.detallehrspersonas)
          console.log(resp)
        }
      }).catch(error => {
        console.log(error)
        this.setError(error)
      })
    },
    setError(error) {
      this.$refs.vehiculosTipo.setError(error)
      this.$refs.vehiculosDia.setError(error)
      this.$refs.personasCalle.setError(error)
      this.$refs.PersonasDia.setError(error)
      this.$refs.personasVehiculos.setError(error)
      this.$refs.afluenciaVehicular.setError(error)
      this.$refs.afluenciaPeatonal.setError(error)
    },
  },

  setup() {
    moment.locale('es')

    return {
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
