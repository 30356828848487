import axios from 'axios'
import environment from '@/environments/environment'
import auth from './auth'

const path = environment.apiUrl

export default {
  getTotalesAgrupacion(params) {
    return axios.post(`${path}/totales_agrupacion`, params, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },
  getTotalesHrs(params) {
    return axios.post(`${path}/totales_movilidad_hrs`, params, {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'Content-Type': 'application/json',
      },
    })
  },
}
