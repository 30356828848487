<template>
  <v-card>
    <v-card-title class="align-start">
      <p class="text-lg-center">
        <span class="font-weight-semibold">Número Total de Personas por Día</span>
      </p>
    </v-card-title>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="!loading && !error"
        class="small"
      >
        <bar-chart
          v-if="datacollection"
          :chart-data="datacollection"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import BarChart from './BarChart'

export default {
  components: {
    BarChart,
  },
  props: {
  },
  data() {
    return {
      datacollection: null,
      loading: true,
      error: false,
      errMessage: '',
    }
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading(load) {
      this.loading = load
      this.error = false
    },
    setError(error) {
      this.datacollection = null
      this.error = true
      this.errMessage = error
      this.loading = false
    },
    fillData(detalle) {
      const chartData = []
      const chartLabels = []
      if (detalle != null) {
        detalle.map(value => {
          chartData.push(value.total)
          chartLabels.push(value.fecha)
          this.loading = false

          return true
        }, {})
        this.datacollection = {
          labels: chartLabels,
          datasets: [
            {
              label: 'Personas',
              backgroundColor: '#028675',
              data: chartData,
            },
          ],
        }
      } else {
        this.datacollection = null
      }
      this.error = false
      this.loading = false
    },
  },
}
</script>
