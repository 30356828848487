<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title class="font-weight-semibold">
        Afluencia Vehícular
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        v-model="stackDays"
        inset
        :label="stackDays? 'Dias': 'Horas'"
      ></v-switch>
    </v-toolbar>

    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <v-alert
        v-if="error"
        outlined
        type="error"
        transition="scale-transition"
      >
        Error en el servicio {{ errMessage }}
      </v-alert>
      <div
        v-if="!loading"
        class="small"
      >
        <bar-stack-chart
          v-if="datacollection"
          :chart-data="datacollection"
          :limits="limits"
        ></bar-stack-chart>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>

import BarStackChart from './BarStackChart'
import chartColor from '@/utils/chartColor'

export default {
  components: {
    BarStackChart,
  },
  props: {
  },
  data() {
    return {
      datacollection: null,
      data: null,
      loading: true,
      error: false,
      errMessage: '',
      stackDays: true,
      limits: {
        inf: 1000,
        medio: 1500,
        sup: 2000,
      },
    }
  },
  watch: {
    stackDays(val) {
      if (val) {
        this.hrFormat(this.data)
      } else {
        this.dayFormat(this.data)
      }
    },
  },
  mounted() {
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    setLoading(load) {
      this.loading = load
      this.error = false
    },
    setError(error) {
      this.datacollection = null
      this.error = true
      this.errMessage = error
      this.loading = false
    },
    fillData(data) {
      this.error = false
      this.loading = false
      this.data = data
      if (data != null) {
        if (this.stackDays) {
          this.hrFormat(this.data)
        } else {
          this.dayFormat(this.data)
        }
      } else {
        this.datacollection = null
      }
    },
    dayFormat(data) {
      const chartLabels = []
      const hrs = {}
      const dataSets = []
      let serieCounter = 0
      if (data != null) {
        data.forEach(value => {
          const l = chartLabels.filter(cl => cl === value.fecha)
          if (l.length === 0) {
            chartLabels.push(value.fecha)
          }

          if (!hrs[value.hr]) {
            hrs[value.hr] = {
              label: value.hr,
              backgroundColor: chartColor.getColor(serieCounter),
              data: [],
            }
            serieCounter += 1
          }

          hrs[value.hr].data.push(value.total)
        })
        Object.keys(hrs).forEach(h => {
          dataSets.push(hrs[h])
        })
      }
      this.datacollection = {
        labels: chartLabels,
        datasets: dataSets,
      }
    },
    hrFormat(data) {
      const chartLabels = [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ]
      const fechas = {}
      const dataSets = []
      let serieCounter = 0
      if (data != null) {
        data.forEach(value => {
          if (!fechas[value.fecha]) {
            fechas[value.fecha] = {
              label: value.fecha,
              backgroundColor: chartColor.getColor(serieCounter),
              data: [],
            }
            serieCounter += 1
          }

          fechas[value.fecha].data.push(value.total)
        })
        Object.keys(fechas).forEach(f => {
          dataSets.push(fechas[f])
        })
      }
      this.datacollection = {
        labels: chartLabels,
        datasets: dataSets,
      }
    },
  },
}
</script>
