import { Pie, mixins } from 'vue-chartjs'
import numeral from 'numeral'

const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue)
              const currentValue = dataset.data[tooltipItem.index]
              const percentage = Math.floor(((currentValue / total) * 100) + 0.5)

              return `${data.labels[tooltipItem.index]}: ${numeral(currentValue).format('0,0.')} (${percentage}%)`
            },
          },
        },
      },
    }
  },

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options)
  },
}
