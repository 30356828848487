import { Bar, mixins } from 'vue-chartjs'
import numeral from 'numeral'

const { reactiveProp } = mixins

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    limits: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        scales: {
          yAxes: [{

            ticks: {
              beginAtZero: true,
              callback: value => numeral(value).format('0,0.'),
            },
            gridLines: {
              display: true,
            },
          }],
          xAxes: [{

            gridLines: {
              display: false,
            },
          }],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ''
              if (label) {
                label += ': '
              }
              label += numeral(tooltipItem.yLabel).format('0,0.')

              return label
            },
          },
        },
        backgroundRules: [{
          backgroundColor: 'rgba(2, 134, 117, 0.2)',
          yAxisSegement: this.limits.inf,
        }, {
          backgroundColor: 'rgba(238, 232, 16, 0.2)',
          yAxisSegement: this.limits.medio,
        }, {
          backgroundColor: 'rgba(238, 36, 16, 0.2)',
          yAxisSegement: Infinity,
        }],
        responsive: true,
        maintainAspectRatio: false,
      },
    }
  },

  mounted() {
    this.addPlugin({
      beforeDraw: chart => {
        const { ctx } = chart.chart
        let ruleIndex = 0
        const rules = chart.chart.options.backgroundRules
        const yaxis = chart.chart.scales['y-axis-0']
        const xaxis = chart.chart.scales['x-axis-0']
        const partPercentage = 1 / (yaxis.ticksAsNumbers.length - 1)
        for (let i = yaxis.ticksAsNumbers.length - 1; i > 0; i -= 1) {
          if (yaxis.ticksAsNumbers[i] < rules[ruleIndex].yAxisSegement) {
            ctx.fillStyle = rules[ruleIndex].backgroundColor
            ctx.fillRect(xaxis.left, yaxis.top + ((i - 1) * (yaxis.height * partPercentage)), xaxis.width, yaxis.height * partPercentage)
          } else {
            ruleIndex += 1
            i += 1
          }
        }
      },
    })
    this.renderChart(this.chartData, this.options)
  },
}
